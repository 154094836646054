@import url("https://fonts.googleapis.com/css?family=Lato:regular,bold,italic&subset=latin,latin-ext");

//@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&family=Roboto:ital,wght@0,400;0,500;1,400;1,500&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Sharp:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

//$font-family-sans-serif: 'Roboto', sans-serif;

//$offcanvas-vertical-height: 80%;

//$input-padding-y: 0.6em;
//$input-button-padding-y: 0.6em;

//$carousel-transition-duration: 7s;

//$dropdown-link-active-color: black;
//$dropdown-link-active-bg: transparent;

//$primary: #0cc;
//$card-bg: #212529;
//$card-border-color: #5a5a5a;

$font-family-sans-serif: "Lato", sans-serif;
//$primary: #761111;
$primary: #1a9197;

//$body-bg: #333;
//$body-color: #e9ecef;
//$modal-content-bg: #212529;
//$modal-content-border-color: #fff;

$form-range-track-bg: #ffda72;
$form-range-thumb-bg: #ff7a12;

// Locating London "Road Sign" font is close to 'Univers Pro 65 Bold' https://www.myfonts.com/products/pro-65-bold-univers-368535
// But letters need to be very slighly narrower and more spacing between them


@import "Base.scss";

nav.navbar {
    //height: 3.5em; // If we specify a height, the navbar toggle appears transparent
    z-index: 1002; // leaflet map z-index seems to be 400. leaflet map widget z-index seems to be 1000.
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
    //background-color: #1a9195;
    //background-color: #01d5c1;
    //border-bottom: 1px solid #fff;
    background-image: url('/data/loclon/header-bg.gif');
    max-height: 3.5rem;
}

div.navbar-collapse {
    //z-index: 1002; // leaflet map z-index seems to be 400. leaflet map widget z-index seems to be 1000.
    //box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
    background-image: url('/data/loclon/header-bg.gif');
    padding: 0.5rem;
    //max-width: 100%;
    //border: 1px solid #777;
    //max-height: 3.5rem;
}

div.offcanvas {
    max-width: 95%;
}

.dropdown-item {
    @include media-breakpoint-down(sm) {
        white-space: normal; // long drop down items extend out of the screen on mobile otherwise  
    }
}

button.navbar-toggler {
    border: none;
}

div.navbar-collapse.show {
    //z-index: 1002; // leaflet map z-index seems to be 400. leaflet map widget z-index seems to be 1000.
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
    //background-image: url('/data/loclon/header-bg.gif');
    //padding: 0.5rem;
    border: 1px solid #777;
    //max-height: 3.5rem;
}


//img.logo-lb {
//    height: 2rem; // Required because otherwise on mobile the logo makes the navbar too high
//}

a.navbar-brand {
    padding-top: 0; // Logo sits too low otherwise
}

img.loclon-logo-horiz {
    max-height: 1.8rem;
}

img.loclon-logo-vert {
    max-height: 2.3rem;
}

div.map-gis-container {
    position: relative;
    margin-top: 3.5rem; // Try not to get overlapped by the fixed navbar
    height: calc(100dvh - 3.5rem) // For now we have to estimate the height of the navbar
}

button.offcanvas-gis-button {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem; // leaflet controls use a 10px margin in the leaflet css
    z-index: 401; // leaflet map z-index seems to be 400. leaflet map widget z-index seems to be 1000
    //color: $primary;
    border: 3px solid #000;
    padding: 0.6rem;
}

img.offcanvas-gis-button-svg {
    max-height: 1.5rem;
}

div.map-legend {
    //font-weight: bold;
    font-size: 1.1rem;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    max-width: 21rem;
    z-index: 401; // leaflet map z-index seems to be 400. leaflet map widget z-index seems to be 1000
    //cursor: pointer;

    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
//    color: black;
//    text-shadow:
//      0px 0px 12px white,
//      0px 0px 12px white,
//      0px 0px 12px white,
//      0px 0px 12px white,
//      0px 0px 12px white,
//      0px 0px 12px white,
//      0px 0px 12px white,
//      0px 0px 12px white,
//      0px 0px 12px white,
//      0px 0px 12px white,
//      0px 0px 12px white,
//      0px 0px 12px white,
//      2px 2px 1px white;
//    background-color: rgba(255, 255, 255, 0.8);
//    box-shadow: 0 0 5px 5px rgba(255, 255, 255, 0.8);
//    padding-left: 1rem;
//    padding-right: 1rem;
}

.explore-link {
    color: #00f3ff;
    font-size: 1rem;
}

.explore-link:hover {
    color: #6ff;
}

div#offcanvasGis {
    z-index: 1001; // leaflet map z-index seems to be 400. leaflet map widget z-index seems to be 1000. Navbar z-index is set to 1002.
    padding-top: 3.4rem;
    box-shadow: -8px -1rem 1rem rgba(0, 0, 0, 0.5);
    background-color: #444;
    color: #fff; 
}

div.offcanvas-body {
  padding-top: 0;
  padding-bottom: 3rem;
}

div#offcanvasGis a {
    color: #2dedf7;
}

/*
i.offcanvas-gis-button-icon {
    //font-size: 1.3rem;
    font-size: 1.2rem;
    //font-weight: bold;
}

span.offcanvas-gis-button-plus {
    display: inline-block;
    margin-right: 0.6rem;
    font-size: 1.4rem;
    font-weight: bold;
}

span.offcanvas-gis-button-text {
    font-size: 1.3rem;
    font-weight: bold;
}
*/

/*
div.offcanvas-backdrop { // We want to be able to manipulate the map while the offcamvas is open, so we hide the backdrop.
    display: none;
}
*/

button.offcanvas-close {
    float: right;
    color: #0ff;
    font-weight: bold;
    font-size: 1.5rem;
    padding: 0;
    //position: absolute;
    //top: 2.7rem;
    //right: 0.5rem;
    //z-index: 999;
}

button.reset-layers {
    float: right;
    color: #fff;
    font-weight: bold;
    font-size: 1.5rem;
    padding-bottom: 0;
    padding-top: 0;
    padding-right: 1rem;
    //position: absolute;
    //top: 2.7rem;
    //right: 0.5rem;
    //z-index: 999;
}

h1.offcanvas-loclon-title {
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
    padding: 0;
    font-size: 2rem;
}

div#offcanvasWelcome {
    z-index: 1001; // leaflet map z-index seems to be 400. leaflet map widget z-index seems to be 1000. Navbar z-index is set to 1002.
    padding-top: 3rem;
    //padding-left: 1rem;
    box-shadow: -8px -1rem 1rem rgba(0, 0, 0, 0.5);
}

button.offcanvas-welcome-button {
    position: absolute;
    display: none;
}

div.offcanvas-body-welcome {
    padding: 0;
}

div.over-the-thames {
    position: relative;
    min-height: 12rem;
    //padding: 1rem;
}

button.offcanvas-welcome-close {
    position: absolute;
    //top: 3rem;
    //right: 0.5rem;
    right: 0rem;
    font-size: 3rem;
    color: #000;
}

div.offcanvas-body-welcome h1 {
    padding-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 0;
}

div.offcanvas-welcome-tagline {
    padding-left: 1rem;
    padding-bottom: 2rem;
    font-style: italic;
    color: #777;
    padding-top: 0.2rem;
}

img.offcanvas-welcome-rocque {
    position: absolute;
    bottom: 0rem;
    right: 0rem;
}

div.under-the-thames {
    position: relative;
    background-color: #1a9197;
    color: #fff;
    border-top: 3px solid #000;
    padding: 1rem;
}

div.under-the-thames a {
    font-weight: bold;
    color: #560002;
}

div.under-the-thames div.text-muted {
    color: #ccc !important;
}

img.offcanvas-welcome-rocque-reflection {
    position: absolute;
    top: 0rem;
    right: 0rem;
}

button.offcanvas-gis-welcome-button {
    //position: absolute;
    //top: 1.6rem;
    //right: 1.6rem; // leaflet controls use a 10px margin in the leaflet css
    //z-index: 401; // leaflet map z-index seems to be 400. leaflet map widget z-index seems to be 1000
    //color: $primary;
    border: 2px solid #000;
    //padding: 0.6rem;
    //margin-top: 1rem;
}











div.layers-control {
    max-height: 20rem;
    //height: 10rem;
    //overflow-x: hidden;
    overflow-y: auto;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 0.5rem;
    background-color: #eee;
    color: #444;
    //scrollbar-gutter: stable; // Required because of bug in Firefox on Windows. This solution did not work. See below. https://stackoverflow.com/a/77610020
    //font-size: 0.9rem;
}

div.layer-control {
    //font-size: 0.8rem;
    padding-left: 0.2rem;
    //padding-right: 0.2rem;
    padding-right: 1.5rem; // Required because of bug in Firefox on Windows. scrollbar-gutter solution above did not work. https://stackoverflow.com/a/77610020 
    display: flex; 
}

div.layer-control-child-left-hit {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 3rem;
}

div.layer-control-child-left-query {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 3rem;
}

div.layer-control-child-right {
    flex-grow: 1; 
}

div.layer-control.active {
    background-color: #ffba52;
}

div.opacity-label {
    color: #ffba52;
}

div.layer-control-separator {
    margin-bottom: 0.8rem;
    //border: 0;
    border-top: 2px solid #777;
    //text-align: left;
    //height: 0px;
    line-height: 0px;
}

div.layer-control-separator-0 {
    margin-top: 0.4rem;
}

div.layer-control-separator-5 {
    margin-top: 1.4rem;
}

.layer-control-separator-title {
    background-color: #eee;
    color: #777;
    font-size: 1rem;
    margin-left: 1rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    font-weight: bold;
}




div.layer-options-modal {
    padding: 0;
}

div.layer-options-modal h1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
}

div.layer-options-inner {
    margin-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

div.layer-options-modal label {
    font-size: 0.9rem;
    color: #777;
    margin-bottom: 0;
    padding-left: 0.5rem;
}


div.layer-key {
    max-height: 8rem;
    overflow-x: hidden;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding-top: 0.3rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-bottom: 0.7rem;
    background-color: #eee;
    color: #444;
    //font-size: 0.8rem;
}

span.layer-key-heading-info {
    font-size: 0.9rem;
    color: #ccc;
    margin-left: 0.5rem;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 15rem;
    vertical-align: bottom;
}

a.nav-link.dropdown-toggle::after {
    content: none;
}

span.paging-icon {
    font-size: 2.4em;
}

button.paging-first {
    color: #0ff;
}

button.paging-first:hover {
    color: #f80;
}

button.paging-previous {
    color: #0ff;
}

button.paging-previous:hover {
    color: #f80;
}

button.paging-next {
    color: #0ff;
}

button.paging-next:hover {
    color: #f80;
}

button.paging-last {
    color: #0ff;
}

button.paging-last:hover {
    color: #f80;
}

span.paging-text {
    font-size: 0.8em;
}

input.paging-input {
    display: inline;
    border-radius: 0;
    width: 4em;
    height: 1.7em;
    margin-top: 0.2em;
    padding: 0.5em;
}

span.input-group-text-ahdsfsp-uniform {
    min-width: 8.6rem;
}

div.post-toc-container {
    padding-top: 3rem;
}

div#post-toc {
    position: fixed;
    //max-width: 20rem;
    margin-left: 3rem;
    //margin-top: 1rem;
    //z-index: -1;
    //border: 2px solid #f00;
    max-height: calc(100dvh - 140px);
    //overflow: auto;
    //background-color: #fff;
}

div#post-toc ul {
    list-style: none;
}

div#post-toc ul li {
    position: relative; // So we can position the dropdown-item caret absolutely, relative to the dropdown-item.
    padding-left: 2em; // Make space for the arrow
    margin-bottom: 0.5rem;
}

div#post-toc ul li a {
    color: #888;
    text-decoration: none;
}

div#post-toc ul li a.active:before { // Display the dropdown-item caret if the dropdown-item is active
     content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 7px solid #444;
    border-right: 7px solid transparent;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    left: 1em;
    top: 0.25em;  
}

div#post-toc ul li a.active {
    color: #000;
}

div.post-toc-bottom-padding {
    padding-bottom: 10rem;
}

h1 {
    margin-bottom: 3rem;
}

h2 {
    margin-top: 3rem;
    scroll-margin-top: 6rem; // https://stackoverflow.com/questions/24665602/scrollintoview-scrolls-just-too-far/56391657#answer-67923821
    margin-bottom: 1rem;
}

a.fake-link {
    cursor: pointer;
}

li.searchmode-heading {
    font-weight: bold;
    margin-left: 0.4rem;
    margin-top: 0.8rem;
}

button.search-feature-mode-select {
    white-space: wrap;
}

button.mode-select-dropdown-item {
    white-space: wrap;
} 

button.search-feature-mode-select::after {
    float: right;
    margin-top: 0.42em;
    font-size: 1.3em;
}

div.setinfo {
    font-size: 0.89rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1.5rem;
    padding-top: 0.5rem;
}

div.form-layerdisplay {
  margin-bottom: 1rem;
}

/*
div.form-layerdisplay select {
  font-size: 0.8rem;
}
*/

button.layer-options-button {
  //font-size: 0.8rem;
  display: inline;
  white-space: normal;
  font-weight: bold;
}

button.warp-button {
  //font-size: 0.8rem;
  display: inline;
  white-space: normal;
  margin-right: 0.3rem;
}

span.agg-legend-square {
  margin-top: 3px;
  margin-right: 0.4rem;
}

input.layer-control-visible-checkbox {
  margin-right: 0.4rem;
}

div.layer-control-query {
  padding-top: 0.2rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #ccc;
}

div.layer-control-hit {
  padding-top: 0.2rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #ccc;
}

span.geotype-place {
  color: #e80;
}

span.geotype-parish {
  color: #0b0;
}

span.geotype-ward {
  color: #f44;
}

span.geotype-gridref {
  color: #66f;
}

button.help-link {
  color: #00f3ff;
  font-size: 0.8rem;
  text-decoration: underline;
}

div.calc-vh {
    min-height: calc(100dvh - 360px);
}

footer {
    color: #aaa;
    background-color: #333;
    position: relative; // This makes the post-toc slide behind it rather than over it. Looks less bad.
}

footer div#footer-top-links {
    /*font-size: 1.2em;*/
    @include media-breakpoint-down(md) {
        font-size: 0.8em;
    }
}

footer div#footer-top-links {
    color: #fff;
    background-color: #666;
}

footer div#footer-top-links a {
    color: #aaa;
    text-decoration: none;
}

footer div#footer-top-links span.vertical-divider {
    margin-left: 0.7em;
    margin-right: 0.7em;
}

footer h1 {
    color: #fff;
}

footer div a {
    color: white;
    text-decoration: none;
}

footer div a:hover {
    color: white;
}

div.footer-version {
    font-size: 1.1rem;
}

footer div#footer-bottom-links {
    font-size: 0.8em;
    @include media-breakpoint-down(md) {
        font-size: 0.5em;
    }
}

footer div#footer-bottom-links {
    color: #fff;
    background-color: #111;
}

footer div#footer-bottom-links a {
    color: #aaa;
    text-decoration: none;
}

footer div#footer-bottom-links span.vertical-divider {
    margin-left: 0.2em;
    margin-right: 0.2em;
}

img.dhilogo {
    max-height: 8rem;
}

