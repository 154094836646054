
@import "../node_modules/bootstrap/scss/bootstrap.scss";

.spin-slow {
  animation: spin-animation 10s infinite linear;
  display: inline-block;
}

.spin-fast {
  animation: spin-animation 1s infinite linear;
  display: inline-block;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.btn-link {
    cursor: pointer;
    text-decoration: none;
    padding: 0;
    border: 0;
    vertical-align: top;
}

.h-10em { // Required so that recharts charts have a specified height and so display
    height: 20em !important;
}

.h-10em-responsive { // Required so that recharts charts have a specified height and so display
    height: 20em !important;
    @include media-breakpoint-down(md) {
        height: 10em !important;
    }

}

.agg-legend-square {
    display: inline-block;
    width: 1em;
    height: 1em;
}

.agg-legend-count {
    margin-left: 4px;
}

.text-toggle[aria-expanded=false] .text-expanded {
  display: none;
}
.text-toggle[aria-expanded=true] .text-collapsed {
  display: none;
}

.recharts-tooltip-wrapper {
	z-index: 100;
}

.recharts-default-tooltip {
    font-family: "Lato", sans-serif;
    background-color: rgba(0, 0, 0, 0.7) !important;
    border: 0 !important;
    padding: 8px !important;
}

.recharts-white-text-tooltip .recharts-tooltip-item-name {
	color: white;
}

.recharts-white-text-tooltip .recharts-tooltip-item-separator {
	color: white;
}

.recharts-white-text-tooltip .recharts-tooltip-item-value {
	color: white;
}

.recharts-tooltip-label {
	color: white;
}

.recharts-tooltip-cursor {
	display: none;
}

@media only screen and (min-width: 600px) {
    .mw-sm-300px { // Required because bootstrap does not have breakpoint responsive width classes
        max-width: 300px;
    }
}

.btn-outline-input-group { // Required because bootstrap does not offer outline buttons which match the colour of input boxes
    border-color: $input-border-color;
}

